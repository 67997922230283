import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NextSeo } from "next-seo";

import Header from "../Header";
import Footer from "../Footer";
import SideMenu from "../SideMenu";
import LastUpdate from "../LastUpdate";
import { NavigationBar } from "../../src/components/atoms";
import { useRouter } from "next/router";
import { Constants, Values } from "../../src/config";
import { generateURLSlug } from "../../helpers";
import { EventDao } from "../../src/DAO";
import moment from "moment";
import Head from "next/head";
import CardSectionList from "../../src/components/molecules/CardList/CardSectionList";

const ArtistFestivalList = (props) => {
  const { t } = useTranslation();
  const { itemData, routes } = props;
  const { id, slug, category, tabType } = routes;
  const [festivalList, setFestivalList] = useState([]);
  const [festivalSection, setFestivalSection] = useState([]);

  const router = useRouter();

  const [sideMenuShow, setSideMenuShow] = useState(false);
  const [sideMenuHeaderShow, setSideMenuHeaderShow] = useState(false);
  const [isMobileVersion, setIsMobileVersion] = useState(false);

  const metaTitle = `${itemData?.nickname} Festivals`;
  const metaDescription = `Discover all the upcoming ${itemData?.nickname} festivals.`;
  const updatedAt = moment().startOf("week").add("2", "days").toISOString();
  const canonicalURL = `https://${
    process.env.NEXT_PUBLIC_WWW_BASE_URL
  }${generateURLSlug(id, slug, null, category, tabType)}`;

  const onLoad = () => {
    EventDao.findWeb({
      pageIndex: 0,
      pageSize: 100,
      artistProfileId: itemData?.profileId,
      isAnytime: true,
    }).then((result) => {
      setFestivalList(result.data);
      setFestivalSection(
        EventDao.groupByYear(
          result.data,
          Constants.dateFilterType.DATE_FILTER_PAST
        )
      );
    });
  };

  useEffect(() => {
    setIsMobileVersion(Values.deviceWidth < 640);
  }, [Values.deviceWidth]);

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div>
      <Head>
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": canonicalURL,
              url: canonicalURL,
              name: metaTitle,
              dateModified: updatedAt,
            }),
          }}
        />
      </Head>
      <NextSeo
        title={`${metaTitle} - ${process.env.NEXT_PUBLIC_APP_NAME}`}
        description={metaDescription}
        canonical={canonicalURL}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: canonicalURL,
          title: `${metaTitle} - ${process.env.NEXT_PUBLIC_APP_NAME}`,
          description: metaDescription,
          images: [
            {
              url: `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}${process.env.NEXT_PUBLIC_BANNER_IMAGE}`,
              width: 1200,
              height: 630,
              alt: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
              type: "image/png",
            },
          ],
          siteName: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
        }}
      />
      <Header setSideMenuShow={setSideMenuShow} />

      <SideMenu
        sideMenuShow={sideMenuShow}
        setSideMenuShow={setSideMenuShow}
        sideMenuHeaderShow={sideMenuHeaderShow}
        setSideMenuHeaderShow={setSideMenuHeaderShow}
      />

      <div className="bg-black">
        <div
          className={`mx-auto relative ${
            isMobileVersion ? "" : "wrapper pt-[30px]"
          } pb-[130px]`}>
          <div className="max-w-1160px min-h-screen flex-1 mx-auto">
            <NavigationBar
              title={metaTitle}
              customStyle={{ marginTop: 0 }}
              isMobileVersion={isMobileVersion}
              onBackPressed={() =>
                router.push(generateURLSlug(id, slug, null, category, tabType))
              }
            />
            <div className="flex flex-row justify-center items-center">
              <div className="flex flex-col justify-center items-center border border-spliterLine px-[10px] py-[5px] rounded-full text-white font-regular sm:text-base text-xs text-center mb-[30px]">
                <div>
                  <span className="text-grayText2">
                    {festivalList?.length || 0}
                  </span>
                  &nbsp;Festivals
                </div>
              </div>
            </div>
            <div>
              <CardSectionList
                items={festivalSection}
                style={{ flex: 1, marginTop: isMobileVersion ? -20 : 0 }}
                category={Constants.categoryType.CATEGORY_FESTIVAL}
                isLoading={false}
                isMobileVersion={isMobileVersion}
              />
            </div>
            {festivalList.length > 0 && (
              <LastUpdate
                isMobileVersion={isMobileVersion}
                updatedAt={updatedAt}
                isGuide
              />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ArtistFestivalList;
